// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournament-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a:not(:last-child) {
        margin-right: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Tournaments/Tournaments.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;IAEnB;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".tournament-cards {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    a:not(:last-child) {\n        margin-right: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
