// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TournamentsCard_card__sRVZ- {
    max-width: 365px;
    height: 200px;
    padding: 25px 0 25px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1;
    border: 1px solid var(--stroke);

    span {
        display: block;
    }
}

span.TournamentsCard_goingNow__8PbNz {
    display: flex;
    align-items: center;
    font-size: 16px;

    &::after {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        background: var(--red);
        margin-left: 10px;
        border-radius: 10px;
    }
}

.TournamentsCard_dates__dTASN {
    margin-top: auto;
    text-align: center;
    width: 150px;
    padding: 6px 0 6px;
    background: var(--red);
    color: var(--white);
    font-size: 16px;
    border-radius: 50px;
}

.TournamentsCard_name__jAuVB {
    margin-top: 3px;
    color: var(--white);
    font-size: 26px;
}

.TournamentsCard_goNow__NHyJq {
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/TournamentsCard/TournamentsCard.module.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,SAAS;IACT,+BAA+B;;IAE/B;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;;IAEf;QACI,WAAW;QACX,cAAc;QACd,UAAU;QACV,WAAW;QACX,sBAAsB;QACtB,iBAAiB;QACjB,mBAAmB;IACvB;AACJ;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":["@import url(../../variables.css);\n\n.card {\n    max-width: 365px;\n    height: 200px;\n    padding: 25px 0 25px 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    flex: 1 1;\n    border: 1px solid var(--stroke);\n\n    span {\n        display: block;\n    }\n}\n\nspan.goingNow {\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n\n    &::after {\n        content: \"\";\n        display: block;\n        width: 4px;\n        height: 4px;\n        background: var(--red);\n        margin-left: 10px;\n        border-radius: 10px;\n    }\n}\n\n.dates {\n    margin-top: auto;\n    text-align: center;\n    width: 150px;\n    padding: 6px 0 6px;\n    background: var(--red);\n    color: var(--white);\n    font-size: 16px;\n    border-radius: 50px;\n}\n\n.name {\n    margin-top: 3px;\n    color: var(--white);\n    font-size: 26px;\n}\n\n.goNow {\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `TournamentsCard_card__sRVZ-`,
	"goingNow": `TournamentsCard_goingNow__8PbNz`,
	"dates": `TournamentsCard_dates__dTASN`,
	"name": `TournamentsCard_name__jAuVB`,
	"goNow": `TournamentsCard_goNow__NHyJq`
};
export default ___CSS_LOADER_EXPORT___;
