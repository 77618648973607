// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./geist/Geist-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./geist/Geist-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./sf-pro/SFProDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./sf-pro/SFProDisplay-Heavy.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Geist';
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Geist';
    font-weight: 500;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
    font-family: 'SF Pro';
    font-weight: 900;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,4CAAmC;AACvC;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,4CAAkC;AACtC;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,4CAA2C;AAC/C;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,4CAAyC;AAC7C","sourcesContent":["@font-face {\n    font-family: 'Geist';\n    font-weight: 400;\n    src: url(./geist/Geist-Regular.ttf);\n}\n\n@font-face {\n    font-family: 'Geist';\n    font-weight: 500;\n    src: url(./geist/Geist-Medium.ttf);\n}\n\n@font-face {\n    font-family: 'SF Pro';\n    font-weight: 400;\n    src: url(./sf-pro/SFProDisplay-Regular.ttf);\n}\n\n@font-face {\n    font-family: 'SF Pro';\n    font-weight: 900;\n    src: url(./sf-pro/SFProDisplay-Heavy.ttf);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
