// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news_cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.orange-button {
    display: block;
    text-align: center;
    margin: 50px auto 0;
    padding: 13px 48px;
    max-width: 220px;
    color: var(--brown);
    background: var(--orange);
    transition: all 0.5s ease;
    border: 1px solid var(--orange);

    &:hover {
        background: rgba(0, 0, 0, 0);
        color: var(--orange);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/News/News.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,+BAA+B;;IAE/B;QACI,4BAA4B;QAC5B,oBAAoB;IACxB;AACJ","sourcesContent":["@import url(../../variables.css);\n\n.news_cards {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.orange-button {\n    display: block;\n    text-align: center;\n    margin: 50px auto 0;\n    padding: 13px 48px;\n    max-width: 220px;\n    color: var(--brown);\n    background: var(--orange);\n    transition: all 0.5s ease;\n    border: 1px solid var(--orange);\n\n    &:hover {\n        background: rgba(0, 0, 0, 0);\n        color: var(--orange);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
