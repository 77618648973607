// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./assets/fonts/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Geist';
  color: var(--gray);
  font-size: 20px;
  background: var(--main-bg);
}

a {
  text-decoration: none;
  color: var(--gray);
  font-size: 20px;
}

.container {
  max-width: 1580px;
  margin: 0 auto;
  padding: 0 30px;
}

section {
  margin-top: var(--section-margin);
}

.section-title {
  font-family: "SF Pro";
  font-weight: 900;
  text-align: center;
  font-size: var(--section-title-size);
  color: var(--white);
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,UAAU;EACV,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,oCAAoC;EACpC,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":["@import url(./variables.css);\n@import url(./assets/fonts/fonts.css);\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Geist';\n  color: var(--gray);\n  font-size: 20px;\n  background: var(--main-bg);\n}\n\na {\n  text-decoration: none;\n  color: var(--gray);\n  font-size: 20px;\n}\n\n.container {\n  max-width: 1580px;\n  margin: 0 auto;\n  padding: 0 30px;\n}\n\nsection {\n  margin-top: var(--section-margin);\n}\n\n.section-title {\n  font-family: \"SF Pro\";\n  font-weight: 900;\n  text-align: center;\n  font-size: var(--section-title-size);\n  color: var(--white);\n  margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
