// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --gray: #b2b2b2;
    --white: #fff;
    --red: #f23f32;
    --yellow: #ffc336;
    --brown: #5b390c;
    --orange: #ff852a;
    --main-bg: #161617;
    --section-margin: 95px;
    --section-title-size: 36px;
    --stroke: #3f3f3f;
}`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,0BAA0B;IAC1B,iBAAiB;AACrB","sourcesContent":[":root {\n    --gray: #b2b2b2;\n    --white: #fff;\n    --red: #f23f32;\n    --yellow: #ffc336;\n    --brown: #5b390c;\n    --orange: #ff852a;\n    --main-bg: #161617;\n    --section-margin: 95px;\n    --section-title-size: 36px;\n    --stroke: #3f3f3f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
