// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../assets/fonts/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GamesCard_card__M6CdR {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 54px 10px;
    border: 1px solid var(--stroke);
    text-align: center;
    max-width: 365px;
    width: 100%;
    height: 480px;
}

.GamesCard_logo__NcCtl {
    width: 85px;
    height: 85px;
}

.GamesCard_date__BJ-B2 {
    font-family: "SF Pro";
    font-weight: 900;
    font-size: 36px;
    color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/GamesCard/GamesCard.module.css"],"names":[],"mappings":"AAGA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":["@import url(../../variables.css);\n@import url(../../assets/fonts/fonts.css);\n\n.card {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-direction: column;\n    padding: 54px 10px;\n    border: 1px solid var(--stroke);\n    text-align: center;\n    max-width: 365px;\n    width: 100%;\n    height: 480px;\n}\n\n.logo {\n    width: 85px;\n    height: 85px;\n}\n\n.date {\n    font-family: \"SF Pro\";\n    font-weight: 900;\n    font-size: 36px;\n    color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `GamesCard_card__M6CdR`,
	"logo": `GamesCard_logo__NcCtl`,
	"date": `GamesCard_date__BJ-B2`
};
export default ___CSS_LOADER_EXPORT___;
