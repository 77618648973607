// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-slider-wrap {
    div {
        width: 100%;
        height: 855px;
        position: relative;
        height: calc((100vw - 30px) * 0.56);

        &:focus-visible {
            outline: none !important;
        }

        img {
            width: 100%;
            height: 100%;

            &:focus-visible {
                outline: none !important;
            }
        }

        a {
            position: absolute;
            right: 35px;
            bottom: 30px;
            display: inline-block;
            color: var(--white);
            font-weight: 400;
            padding: 10px 25px;
            background: var(--red);
            border: 1px solid var(--red);
            transition: all .5s ease;

            &:hover {
                background: var(--white);
                color: var(--red);
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .team-slider-wrap div {
        height: 855px;
    }
}

.slick-dots button::before {
    color: var(--gray) !important;
}

.slick-dots {
    bottom: -35px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Team/Team.css"],"names":[],"mappings":"AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,kBAAkB;QAClB,mCAAmC;;QAEnC;YACI,wBAAwB;QAC5B;;QAEA;YACI,WAAW;YACX,YAAY;;YAEZ;gBACI,wBAAwB;YAC5B;QACJ;;QAEA;YACI,kBAAkB;YAClB,WAAW;YACX,YAAY;YACZ,qBAAqB;YACrB,mBAAmB;YACnB,gBAAgB;YAChB,kBAAkB;YAClB,sBAAsB;YACtB,4BAA4B;YAC5B,wBAAwB;;YAExB;gBACI,wBAAwB;gBACxB,iBAAiB;YACrB;QACJ;IACJ;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["@import url(../../variables.css);\n\n.team-slider-wrap {\n    div {\n        width: 100%;\n        height: 855px;\n        position: relative;\n        height: calc((100vw - 30px) * 0.56);\n\n        &:focus-visible {\n            outline: none !important;\n        }\n\n        img {\n            width: 100%;\n            height: 100%;\n\n            &:focus-visible {\n                outline: none !important;\n            }\n        }\n\n        a {\n            position: absolute;\n            right: 35px;\n            bottom: 30px;\n            display: inline-block;\n            color: var(--white);\n            font-weight: 400;\n            padding: 10px 25px;\n            background: var(--red);\n            border: 1px solid var(--red);\n            transition: all .5s ease;\n\n            &:hover {\n                background: var(--white);\n                color: var(--red);\n            }\n        }\n    }\n}\n\n@media screen and (min-width: 1600px) {\n    .team-slider-wrap div {\n        height: 855px;\n    }\n}\n\n.slick-dots button::before {\n    color: var(--gray) !important;\n}\n\n.slick-dots {\n    bottom: -35px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
