// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.games-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a:not(:last-child) {
        margin-right: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Games/Games.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".games-flexbox {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    a:not(:last-child) {\n        margin-right: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
