// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsCard_card__MTqih {
    max-width: 490px;
    max-height: 590px;
    width: 100%;
    height: 590px;
    padding: 370px 15px 10px;
    position: relative;
    border: 1px solid var(--stroke);

    &:not(:last-child) {
        margin-right: 20px;
    }

    &:hover .NewsCard_background__8DCg0 {
        filter: brightness(30%);
        transition: filter .5s ease;
    }
}

.NewsCard_date__vxYa1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    width: 132px;
    height: 40px;
    border-radius: 40px;
    background-color: var(--red);
}

.NewsCard_title__7fSsV {
    display: block;
    font-weight: 500;
    color: var(--white);
    font-size: 26px;
    max-width: 290px;
    margin-top: 9px;
}

.NewsCard_description__w4A3J {
    display: block;
    max-width: 370px;
    margin-top: 8px;
}

.NewsCard_background__8DCg0 {
    transition: filter .5s ease;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    filter: brightness(40%);
}`, "",{"version":3,"sources":["webpack://./src/components/NewsCard/NewsCard.module.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,wBAAwB;IACxB,kBAAkB;IAClB,+BAA+B;;IAE/B;QACI,kBAAkB;IACtB;;IAEA;QACI,uBAAuB;QACvB,2BAA2B;IAC/B;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,WAAW;IACX,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":["@import url(../../variables.css);\n\n.card {\n    max-width: 490px;\n    max-height: 590px;\n    width: 100%;\n    height: 590px;\n    padding: 370px 15px 10px;\n    position: relative;\n    border: 1px solid var(--stroke);\n\n    &:not(:last-child) {\n        margin-right: 20px;\n    }\n\n    &:hover .background {\n        filter: brightness(30%);\n        transition: filter .5s ease;\n    }\n}\n\n.date {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: var(--white);\n    width: 132px;\n    height: 40px;\n    border-radius: 40px;\n    background-color: var(--red);\n}\n\n.title {\n    display: block;\n    font-weight: 500;\n    color: var(--white);\n    font-size: 26px;\n    max-width: 290px;\n    margin-top: 9px;\n}\n\n.description {\n    display: block;\n    max-width: 370px;\n    margin-top: 8px;\n}\n\n.background {\n    transition: filter .5s ease;\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    z-index: -1;\n    width: 100%;\n    height: 100%;\n    filter: brightness(40%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `NewsCard_card__MTqih`,
	"background": `NewsCard_background__8DCg0`,
	"date": `NewsCard_date__vxYa1`,
	"title": `NewsCard_title__7fSsV`,
	"description": `NewsCard_description__w4A3J`
};
export default ___CSS_LOADER_EXPORT___;
